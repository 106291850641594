import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import ReactToolTip from 'react-tooltip'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'

import { urlFor, client } from '../../client'
import { AppWrap, MotionWrap } from '../../wrapper'
import './Footer.scss';
import { images } from '../../constants'

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h2 className="head-text">Take a Coffe & Chat with me</h2>
      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt='email' />
          <a href="mailto:siddheshkukade2003@gmail.com" className='p-text'>siddheshkukade2003@gmail.com</a>
        </div>
        <div className='app__footer-card'>
          <img src={images.email} alt='email' />
          <a href={`tel:${process.env.REACT_APP_PHONE_NO}`} className='p-text'>+91 (983) 466-0290</a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
          </div>
          <div className="app__flex">
            <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>)
}

export default AppWrap(MotionWrap(Footer, "app__footer"), 'contact', 'app__whitebg')